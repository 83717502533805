import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Accordion as GAccordion, AccordionPanel } from 'grommet';
import HtmlContent from './htmlContent';
import { createMarkup } from '../utils';
import { MaxWidth } from './maxWidth';
import Intro from './intro';

interface Item {
    item: string;
    itemHeading: string;
}

interface props {
    heading: string;
    intro: string;
    items: Item[];
    isFaq?: boolean;
}

const Accordion: FC<props> = ({ heading, intro, items, isFaq }) => {
    const schema = {
        '@context': 'http://schema.org',
        '@type': 'FAQPage',
        mainEntity: items.map(({ item, itemHeading }) => ({
            '@type': 'Question',
            name: itemHeading,
            acceptedAnswer: {
                '@type': 'Answer',
                text: item,
            },
        })),
    };

    return (
        <>
            {isFaq && (
                <Helmet encodeSpecialCharacters={false}>
                    <script type="application/ld+json">{JSON.stringify(schema)}</script>
                </Helmet>
            )}
            <Box pad={{ vertical: 'xlarge' }} className="light-1">
                {heading && <Intro heading={heading} intro={intro} level="2" />}
                <MaxWidth>
                    <GAccordion>
                        {items &&
                            items.map(({ item, itemHeading }, index) => (
                                <AccordionPanel label={itemHeading} key={index}>
                                    <Box pad="medium" background="light-2">
                                        <HtmlContent dangerouslySetInnerHTML={createMarkup(item)} />
                                    </Box>
                                </AccordionPanel>
                            ))}
                    </GAccordion>
                </MaxWidth>
            </Box>
        </>
    );
};

export default Accordion;
